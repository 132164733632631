/* custom.scss */
$primary: #323232;
$danger: rgb(252, 121, 176);
/* end custom.scss */

@import '~bootstrap/scss/bootstrap.scss';

.btn-danger {
    --bs-btn-color: var(--bs-white) !important;
    --bs-btn-hover-color: var(--bs-white) !important;
}