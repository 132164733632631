.hero-call-to-action {
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(255, 103, 47);
    transition: 0.3s;
    color: #fff;
}

.hero-call-to-action a {
    color: #fff;
}

.hero-call-to-action:hover {
    background-color: rgb(252, 121, 176);
}

@media only screen and (min-width: 768px) {
    .max-width-80 {
        max-width: 80%;
    }
}

.grid-cat-text h1 {
    font-size: 7vh;
}

.grid-cat-text h3 {
    font-size: 5vh;
}

.grid-cat-desc {
    max-width: 85%;
    margin: auto;
    text-align: justify;
}

@media only screen and (max-width: 768px) {
    .grid-cat-desc {
        text-align: center;
        font-size: 17px !important;
    }
}

@media only screen and (max-width: 768px) {
    #creation-sticky .container-sticky .sticky-col {
        background-color: rgb(252, 121, 176);
        color: #18223b;
    }
}

.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    height: 35px;
    margin-bottom: 20px;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s ease infinite;
    font-weight: 500;
    color: #fff;
    line-height: 35px;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@media only screen and (min-width: 768px) {
    .desktop-reverse {
        flex-direction: row-reverse;
    }
}

#hero-home {
    background-size: cover;
    background-position: center;
    background-image: url('../assets/hero-home.jpg');
}

#hero-creation {
    background-size: cover;
    background-position: bottom;
    background-image: url('../assets/hero-creation.jpg');
}

#hero-userguide {
    background-size: cover;
    background-position: bottom;
    background-image: url('../assets/hero-userguide.jpg');
}

@media only screen and (max-width: 768px) {
    #hero-home {
        background-image: url('../assets/hero-home-mobile.jpg');
        align-items: start !important;
        padding-top: 160px !important;
    }
    #hero-creation {
        background-image: url('../assets/hero-creation-mobile.jpg');
        align-items: start !important;
        padding-top: 160px !important;
    }
    #hero-userguide {
        background-image: url('../assets/hero-userguide-mobile.jpg');
        align-items: start !important;
        padding-top: 160px !important;
    }
}