#footer {
    background-color: #18223b;
}

#footer h4 {
    color: #fff;
    font-size: 13px;
    margin-top: 30px;
}

#footer p, #footer li {
    font-size: 11px;
}

#footer li {
    list-style-type: none;
}

.footer-social-icon {
    border: 1px solid #fff;
    border-radius: 10px;
    width: 26px;
    height: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}