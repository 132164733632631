#header {
    /*
    background-color: rgba(213, 235, 241, 0.6);
    */
    background-color: #18223bbc;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    max-width: 80%;
}

#header-row-container {
    margin: 14px !important;
}

#header-navbar {
    text-transform: capitalize;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

#header-navbar .nav-link {
    text-decoration: none;
    /*
    color: #18223b;
    */
    color: #d5ebf1;
    font-size: 14px;
    position: relative;
    display: block;
    text-align: center;
    margin: 0 10px;
    padding: 5px 10px;
    transition: 0.3s;
}

#header-navbar .nav-link:hover {
    color: rgb(255, 103, 47);
    transition: 0.3s;
}

@media only screen and (max-width: 768px) {
    #header-navbar {
        display: none;
    }
    #header {
        max-width: 94%;
    }
    #header-row-container {
        margin: 12px !important;
    }

    #burger-menu {
        display: block;
        width: 26px;
        height: 16px;
        background-color: transparent;
        margin: auto;
        position: relative;
    }
    .line-burger-menu {
        width: 26px;
        height: 2px;
        display: block;
        background-color: #d5ebf1;
        margin-bottom: 6px;
        transition: 0.3s;
    }
    #menu-mobile {
        transition: 1.3s;
        transition-property: all;
        transition-property: height;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(.23,1,.32,1);
        will-change: height;
        z-index: 9;
        overflow: hidden;
        height: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #header[data-menu-mobile='open'] #menu-mobile {
        height: 80vh;
    }
    #menu-mobile .navbar-nav {
        opacity: .1;
        padding: 0px 20px 30px;
        transition: 1.3s;
        transition-property: all;
        transition-property: opacity;
        transition-timing-function: ease;
        transition-timing-function: cubic-bezier(.23,1,.32,1);
        will-change: opacity;
    }
    #menu-mobile .nav-link {
        color: #d5ebf1;
        margin-bottom: 30px;
        display: block;
        width: 100%;
    }
    #header[data-menu-mobile='open'] #menu-mobile .navbar-nav {
        opacity: 1;
    }
    #header[data-menu-mobile='open'] #burger-menu .line-burger-menu:nth-child(1) {
        transform: rotate(45deg);
        position: absolute;
        top: 7px;
    }
    #header[data-menu-mobile='open'] #burger-menu .line-burger-menu:nth-child(2) {
        transform: rotate(-45deg);
        position: absolute;
        top: 7px;
    }
    #header[data-menu-mobile='open'] #burger-menu .line-burger-menu:nth-child(3) {
        display: none;
    }
}