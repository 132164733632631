.container-sticky {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container-sticky .sticky-col {
    width: 50%;
    padding-top: 0;
}

.container-sticky .scroll-col {
    width: 43%;
    padding-top: 0px;
}

@media only screen and (max-width: 768px) {
    .container-sticky .sticky-col,
    .container-sticky .scroll-col {
        width: 100%;
    }

    .container-sticky .sticky-col {
        border-bottom: 1px solid #d5ebf1;
        padding: 20px;
    }

    .container-sticky .scroll-col {
        padding: 20px;
    }

    .container-sticky {
        border: 1px solid #d5ebf1;
        margin-bottom: 15px;
        margin-top: 15px;
        border-radius: 10px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 768px) {
    .container-sticky .sticky-col>div {
        position: sticky;
        top: 100px;
    }

    .container-sticky {
        padding-top: 90px;
    }
}