@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
    background-color: #fff;
}

#app-container {
    min-height: calc(100vh - 180px);
    background-color: #18223b;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    color: #18223b;
}

#app-container h1 {
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    word-break: break-word;
}

#app-container p, #app-container li {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
}

#app-container p b {
    font-weight: 600;
}

#app-container p.lead {
    font-size: 18px;
}

#app-container a {
    text-decoration: none;
}

#app-container hr {
    width: 60px;
    margin-left: auto;
    margin-right: auto;
}

.bg-palette-pink {
    background-color: rgb(252, 121, 176) !important;
}

.bg-palette-orange {
    background-color: rgb(255, 103, 47) !important;
}

.bg-palette-blue {
    background-color: #18223b !important;
}

.bg-palette-gray {
    background-color: #eee !important;
}

.bg-palette-lightblue {
    background-color: #d5ebf1 !important;
}

.bg-palette-lightblue-gradient {
    background: #d5ebf1;
    background: linear-gradient(180deg, rgba(213,235,241,1) 70%, rgba(24,34,59,1) 100%)
}

.text-palette-pink {
    color: rgb(252, 121, 176) !important;
}

.text-palette-orange {
    color: rgb(255, 103, 47) !important;
}

.text-palette-blue {
    color: #18223b !important;
}

.text-palette-gray {
    color: #eee !important;
}

.text-palette-lightblue {
    color: #d5ebf1 !important;
}

@media only screen and (max-width: 768px) {
    .only-desktop {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) {
    .only-mobile {
        display: none !important;
    }
    .container-lg {
        max-width: 80% !important;
    }
}